import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Articles`}</h2>
    <ProjectCard title="appium-flutterfinder-java-0.1.1" link="https://github.com/renjujv/appium-flutterfinder-java" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard"> 
Element finder plugin for integrating Flutter Apps testing using Appium. 
The plugin helps to find the elements using key locator in an Android App developed using Flutter.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      