import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`QA Automation and Web Dev Projects`}</h2>
    <ProjectCard title="appium-flutterfinder-java-0.1.1" link="https://github.com/renjujv/appium-flutterfinder-java" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Element finder plugin for integrating Flutter Apps testing using Appium. 
  The plugin helps to find the elements using key locator in an Android App developed using Flutter.
    </ProjectCard>
    <ProjectCard title="MeOr - Media Organizer" link="https://github.com/renjujv/MeOr" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  MeOr aims to be an easy to use app for organizing your media collection. 
  Part of academic mini project for Cochin University Bachelors Degree course.
    </ProjectCard>
    <ProjectCard title="Xml2Html plugin" link="https://github.com/renjujv/Xml2HtmlPlugin" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Transforming xml file to html using xslt stylesheet
    </ProjectCard>
    <ProjectCard title="hotel-booking-demo" link="https://github.com/renjujv/hotel-booking-demo" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Hotel booking app demo with Spring boot Backend and Angular 10 FrontEnd App
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      