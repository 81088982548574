import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h2>{`I'm looking for`}</h2>
    <p>{`Early stage SaaS startups that gives preference in the order - `}<strong parentName="p">{`Employee > Customer > Company`}</strong></p>
    <p>{`I stongly believe what Richard Branson says:
"Train people well enough so they can leave, treat them well enough so they don't want to"`}</p>
    <p>{`Because gratitude is an inherent trait for most humans and when they are treated well,
they are inclined to pass the good to their customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      